import React, { useState } from 'react';
import 'react-lazy-load-image-component/src/effects/blur.css';
import cx from 'classnames';
import get from 'lodash/get';
import map from 'lodash/map';
import Carousel, { CarouselSlideRenderControlProps } from 'nuka-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import { formatCurrency } from '../../utils/formatNumber';
import { formatKm } from '../../utils/mask';
import { PARTS_NUMBER } from '../../utils/productType';
import { Ripple } from '../Ripple';
import { LeftControl } from './LeftControl';
import { RightControl } from './RightControl';
import styles from './style.module.css';

const mocInstallments = 'ou até em 12x sem juros';
interface Props {
  carName: string;
  modelYear?: number;
  fabricationYear?: number;
  clientName: string;
  price: string | number;
  km?: number | string | null;
  LeftControl?: CarouselSlideRenderControlProps;
  urlPictures: string[];
  url?: string;
  vehicleType?: string | number;
  installments?: boolean;
  isNew?: boolean;
}
export const CardAd = ({
  carName = '',
  modelYear,
  fabricationYear,
  km,
  clientName,
  price,
  urlPictures,
  url = '#',
  vehicleType,
  installments,
  isNew,
}: Props) => {
  const dataValueMessage = `${clientName} ${carName} ${fabricationYear}/${modelYear} ${km} ${price}`;
  const s3 = process.env.FTP_IMAGENS;
  const [clicked, setClicked] = useState(false);
  const defaultPhoto = 'images/home/image-veiculodefault.jpg';
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const setVehicle = () => {
    return (
      <>
        <p className="mt0 mb8 mb16-m mb16-l f11 f14-m f14-l fw3">
          {fabricationYear}/{modelYearConvert()}
          <span className="ml12 ml16-l">{setFormatKm() ? `${setFormatKm()} km` : ''}</span>
        </p>
        <p className="mt0 mb8 mb4-m mb4-l f16 f26-m f26-l fw6 abbey lh-paragraph-l">
          {formatCurrency(price)}
        </p>
      </>
    );
  };
  const setPartsProps = () => {
    return (
      <>
        <p className="mt0 mb8 mb16-m mb16-l f11 f12-m f12-l fw3">{isNew ? 'Nova' : 'Usada'}</p>
        <div className={cx('mb4-m mb4-l', { mb8: !installments })}>
          <h4 className="mt0 mb0 mb2-m mb2-l f16 f26-m f26-l fw6 abbey lh-paragraph-l">
            {formatCurrency(price)}
          </h4>
          <p className={cx('mb0 mt0 f12-m f12-l fw3', styles.f10)}>
            {installments ? mocInstallments : ''}
          </p>
        </div>
      </>
    );
  };

  const handleClick = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setClicked(!clicked);
    if (url !== '#') {
      setTimeout(() => {
        if (typeof window !== 'undefined') {
          if (isMobile) {
            window.location.assign(url);
          } else {
            window.open(url);
          }
        }
      }, 600);
    }
  };

  const verifyUrl = (urlImage: string) => {
    if (!urlImage) {
      return `${s3}${defaultPhoto}`;
    }
    if (urlImage.indexOf('data:image/') !== -1 || urlImage.indexOf(`${s3}`) !== -1) {
      return (urlImage = urlImage.replace(`${s3}`, ''));
    }
    return `${s3}thumbs/${urlImage}`;
  };

  const mapImages = (imageCarName: string) => {
    return map(urlPictures, (urlImg, index) => {
      return (
        <LazyLoadImage
          alt={`Foto da galeria ${index + 1}: Veículo ${imageCarName}`}
          src={verifyUrl(urlImg)}
          effect="blur"
          className={styles.fitImage}
          wrapperClassName={styles.imgsize}
          key={index}
          title={`Veículo ${imageCarName}`}
        />
      );
    });
  };

  const isCarouselOrImg = (photo: string, imageCarName: string) => {
    const styleImg = cx('w-100', styles.sizePhoto);
    if (urlPictures && urlPictures.length > 1) {
      return (
        <Carousel
          renderCenterLeftControls={LeftControl}
          renderCenterRightControls={RightControl}
          swiping
        >
          {mapImages(imageCarName)}
        </Carousel>
      );
    }
    return (
      <LazyLoadImage
        alt={`Veículo ${imageCarName}`}
        src={verifyUrl(photo)}
        effect="blur"
        className={styles.fitImage}
        wrapperClassName={styleImg}
        title={`Veículo ${imageCarName}`}
      />
    );
  };

  const handleImages = (imageCarName: string) => {
    const carouselClass = cx('w-100 flex', styles.carouselClass, 'carousel-card-ad');
    const imgClass = cx(
      'w-100 flex relative justify-center overflow-hidden items-center',
      styles.imgClass,
    );
    const urlImg = get(urlPictures, '[0]', '');
    const cxImage = cx('w-auto h-auto self-center', styles.responsiveImage);
    return (
      <div key="carousel">
        <MediaQuery maxWidth={1023}>
          <div className={imgClass} key="imgCard">
            {
              // tslint:disable-next-line: jsx-no-multiline-js
              /* <button className={favoriteIconClass} onClick={handleButton}>
              <Icon name={favoriteIconStatus} width={14} height={14} />
            </button> */
            }
            <LazyLoadImage
              alt={`Veículo ${imageCarName}`}
              src={verifyUrl(urlImg)}
              effect="blur"
              className={styles.fitImage}
              wrapperClassName={cxImage}
              title={`Veículo ${imageCarName}`}
            />
          </div>
        </MediaQuery>
        <MediaQuery minWidth={1024}>
          <div className={carouselClass} key="carouselCard">
            {isCarouselOrImg(urlImg, imageCarName)}
          </div>
        </MediaQuery>
      </div>
    );
  };
  const ellipsis = (phrase: string, where: number) => {
    if (!phrase) {
      return phrase;
    }
    return phrase.substring(0, where) + (phrase.length > where ? '...' : '');
  };

  const modelYearConvert = () => {
    const yearModel = modelYear ? modelYear.toString() : '';
    return yearModel.slice(2, 4);
  };

  const setFormatKm = () => km && formatKm(km.toString());

  const sectionMain = cx('bg-white br10 overflow-hidden', styles.heightCard, {
    pointer: url !== '#',
    [styles.card]: !clicked,
    relative: !clicked,
  });
  const carNameClass = cx(
    'mt0 mb8 mb4-m mb4-l pr32-m pr32-l f12 f18-m f18-l fw7 lh-title lh-solid-m lh-solid-l',
    styles.carNameClass,
  );

  return (
    <a
      className="db no-underline mw-100 gtmCardVehicle"
      href={url}
      target={isMobile ? '_blank' : '_self'}
      onClick={handleClick}
      data-value={dataValueMessage}
      rel="noreferrer"
    >
      <section className={sectionMain}>
        {handleImages(carName)}
        <Ripple extendClassname="h-50" rippleSlow>
          <div
            className="pt16 pt24-m pt24-l ph8 ph16-m ph16-l abbey relative-m relative-l gtmCardVehicle"
            key="containerDataCard"
          >
            <h3 className={carNameClass} title={carName}>
              {carName}
            </h3>
            <div>{vehicleType === PARTS_NUMBER ? setPartsProps() : setVehicle()}</div>
            <p
              className="gtmCardVehicle mt0 mb12 mb16-m mb16-l f11 f14-m f14-l gray lh-copy"
              title={clientName}
            >
              {ellipsis(clientName, 17)}
            </p>
          </div>
        </Ripple>
      </section>
    </a>
  );
};
